<template>
  <section class="large">
    <v-snackbar v-model="snackbar">
      {{ text }}

      <v-btn color="light-green" text @click="snackbar = false">
        Got it!
      </v-btn>
    </v-snackbar>
    <v-sheet tile>
      <video-background
        :src="require('@/assets/Main/streams.mp4')"
        style="max-height: 900px; height: 90vh;"
        :gradient="gradient"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5" />
          </v-row>
        </template>

        <v-container
          dark
          fill-height
          fill-width
          fluid
          :px-12="$vuetify.breakpoint.mdAndUp"
          height="100%"
        >
          &nbsp;

          <v-row align="center" justify="end">
            <v-slide-x-reverse-transition appear>
              <v-col cols="12" md="5" class="pt-12">
                <div>
                  <h1
                    :class="[
                      $vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline'
                    ]"
                    class="font-weight-bold"
                    style="color:white"
                  >
                    Twin Streams
                    <br />Indigenous Nursery
                  </h1>

                  <v-divider class="mb-3" />

                  <div class="subtitle mb-4">
                    <p style="color:white">
                      <em>
                        Our Specialist indigenous nursery in a unique tropical
                        coastal location.
                        <br />
                        Situated in a rehabilitated forest where over 80 000
                        trees have been planted.
                      </em>
                    </p>
                  </div>

                  <v-btn
                    style="color:white"
                    class="ma-0"
                    outlined
                    tile
                    large
                    to="/retail-nursery"
                  >
                    Retail

                    <v-icon right>
                      {{ svgSprout }}
                    </v-icon>
                  </v-btn>

                  <v-btn
                    style="color:white"
                    class="ma-1"
                    to="/wholesale-nursery"
                    outlined
                    tile
                    large
                  >
                    Wholesale
                    <v-icon right>
                      {{ svgTree }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-slide-x-reverse-transition>
          </v-row>
        </v-container>
      </video-background>
      <!--BEGIN COOKIE CONSENT-->
      <v-col>
        <div class="text-center">
          <v-dialog v-model="dialog" width="500">
            <v-card>
              <v-card-title
                style="padding: 0px 24px !important; font-size: 1em !important; line-height: 1.43;"
                class=" grey text-center black--text "
              >
                <h1
                  class="text-center my-3 ml-auto mr-auto  headline  lighten-2"
                >
                  🎉 September Arbour Giveaway! 🎉
                </h1>

                <!-- <v-icon
                  class=" mr-n2 mt-n2"
                  small
                  color="black"
                  @click="dialog = false"
                  >mdi-close</v-icon
                > -->
              </v-card-title>

              <v-card-text class=" text-center pt-3">
                <p>
                  Come during September and get a tree of the year!!
                </p>
                <p class="caption" style="font-weight:bold">
                  Both the Warburgia salutaris and Portulacaria afra were
                  declared SANBI 2021 trees of the year.
                </p>
                <p style="font-weight:bold">
                  🌳 FREE Warburgia salutaris (Pepperbark) per person!
                </p>

                <p style="font-weight:bold">
                  🌳 Portulacaria afra (Porkbush) for only R20!
                </p>
              </v-card-text>

              <!-- <v-divider /> -->

              <v-card-actions class="mt-n2">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    block
                    :elevation="hover ? 22 : 2"
                    class="btn green black--text"
                    style="border-radius: 8px !important;"
                    to="/specials"
                  >
                    More Info
                  </v-btn>
                </v-hover>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
      <!--END COOKIE CONSENT -->
    </v-sheet>
    <boasters />

    &nbsp; &nbsp;
  </section>
</template>

<script>
// import VideoParallax from 'vuetify-video-parallax'
import {
  mdiGlassWine,
  mdiCompass,
  mdiSproutOutline,
  mdiPalmTree
} from "@mdi/js";
export default {
  metaInfo() {
    return {
      title: "Creative"
    };
  },

  components: {
    // Hero: () => import('./Hero')
    // VideoParallax
    Boasters: () => import("@/components/Boasters.vue")
  },
  props: {
    gradient: {
      type: String,
      default: "to top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)"
    }
  },
  data: () => ({
    snackbar: true,
    text: `Hello, This website uses cookies to ensure you get the best experience on our website 🍪 👮`,
    dialog: true,
    svgWine: mdiGlassWine,
    svgCompass: mdiCompass,
    svgSprout: mdiSproutOutline,
    svgTree: mdiPalmTree
  }),

  computed: {
    // attrs () {
    //   return {
    //     key: this.$route.path,
    //     style: {
    //       minHeight: 'calc(100vh - ' + this.$vuetify.application.top + 'px'
    //     }
    //   }
    // }
  }
};
</script>

<style scoped>
.v-card {
  border-radius: 0px;
}
.large {
  padding-top: 0px;
  padding-bottom: 40px;
}

.section {
  padding-bottom: 40px;
}
</style>
